.workorders {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
    }
    &_rightButton {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
