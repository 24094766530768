@import '../../styles/colors';

.assignations {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filterby {
    }
  }
  &_message {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 10px;
  }
  &_message_client {
    display: flex;
    font-size: 20px;
    text-align: center;
    color: $color-check;
  }
  &_list {
    &_item {
      margin-top: 20px;
      &_header {
        padding: 10px 10px 0 10px;
      }
    }
    &_empty {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }
  }
  &_workOrderHeader {
    background-color: $color-light;
    border-radius: 10px;
    display: flex;
    &_info {
      display: grid;
      grid-template-columns: 160px 240px 160px 160px 1fr 80px;
      grid-gap: 5px;
      padding: 10px 10px 5px 10px;
      flex-grow: 1;
      text-align: center;
      &_item {
        border-left: 1px solid $color-cancel;
        padding: 5px;
        &_field {
          font-family: 'OpenSans-Bold';
          font-size: 10px;
          &_percentage {
            font-family: 'OpenSans-Bold';
            font-size: 15px;
          }
        }
        &_data {
          font-size: 12px;
          &.num {
            font-family: 'OpenSans-Bold';
            font-size: 16px;
            color: $color-primary;
          }
          &_percentageComplete {
            font-family: 'OpenSans-Bold';
            text-align: center;
            font-size: 20px;
            color: $color-check;
          }
          &_percentageNoComplete {
            font-family: 'OpenSans-Bold';
            text-align: center;
            font-size: 20px;
            color: red;
          }
        }
      }
      :nth-child(3) {
        grid-column: 3 / last-line;
      }
    }
    &_check {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed $color-primary;
      background-color: $color-primary;
      width: 60px;
      cursor: pointer;
    }
  }
  &_drive_link {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    &_ref {
      width: 80px;
      font-size: 10px;
    }
  }
  &_percentageNoComplete {
    text-align: center;
    width: 200px;
    color: red;
  }
  &_percentageComplete {
    text-align: center;
    width: 200px;
    color: $color-check;
  }
  &_serviceName {
    cursor: pointer;
  }
}
