@import './../../styles/colors';

.clientDocumentRequireds {
  &_addButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

}
