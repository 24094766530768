@import '../../../styles/colors';

.workOrdersOrdersModal {
  &_table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    // padding: 5px;
    margin: 20px;
    text-align: center;
  }
  &_redirectDetails {
    cursor: pointer;
  }
}
