@import '../../../styles/colors';

.messagesModal {
  height: 400px;
  &_send {
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-column-gap: 20px;
    align-items: center;
    &_button {
      color: white;
      background-color: $color-primary;
      border-radius: 6px;
      padding: 10px;
      cursor: pointer;
    }
  }
  &_container {
    background-color: $color-light;
    padding: 10px;
    height: 80%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    &_message {
      width: 45%;
      padding-bottom: 10px;
      &.mine {
        align-self: flex-end;
      }
      &_text {
        border: 1px solid $color-normal;
        border-radius: 6px;
        padding: 10px;
        font-size: 12px;
        background-color: $color-light;
        &.mine {
          background-color: $color-complementary-3;
          color: white;
        }
      }
      &_info {
        margin-top: 5px;
        text-align: right;
        font-size: 10px;
        color: $color-secondary;
        &.mine {
          align-self: flex-end;
        }
      }
    }
  }
}
