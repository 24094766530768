.serviceModal {
  display: grid;
  grid-template-columns: 140px repeat(2, 1fr) 140px;
  grid-gap: 10px;
  &_active {
    justify-self: end;
    grid-column: 4 / 5;
  }
  #MUI_title {
    grid-column: 1 / 4;
  }
  #MUI_description {
    grid-column: 1 / 5;
  }
  #MUI_max_time {
    Input {
      text-align: right;
    }
  }
}
