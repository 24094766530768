@import '../../../styles/colors';

.clientmodal {
  &_company {
    display: grid;
    grid-template-columns: 1fr 40px 2fr;
    grid-gap: 10px;
    align-items: center;
    #MUI_legal_name {
      grid-column: 2 / 4;
    }
    #MUI_name {
      grid-column: 1 / 3;
    }
    #MUI_legal_representative {
      grid-column: 3 / 5;
    }
  }

  &_contact-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
    &_title {
      color: $color-cancel;
    }
    &_risk {
      color: $color-normal;
      font-size: 12px;
      display: flex;
      align-items: center;
      &_label {
        margin-right: 10px;
      }
      &_buttons {
        display: flex;
        justify-content: flex-end;
        &:first-child {
          color: lime;
        }
        &_button {
          width: 50px !important;
          min-width: 50px !important;
          height: 25px !important;
          border-radius: 0 !important;
          &.selected {
            background-color: blue !important;
          }
        }
      }
    }
  }

  &_contact {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
  }

  &_location,
  &_infrastructure {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
