.clientDocuments {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_buttons {
      display: grid;
      grid-template-columns: 250px 250px 250px;
      grid-gap: 10px;
    }
  }
}
