@import '../../styles/colors';

.dashboard {
  &_header {
    display: grid;
    grid-template-columns: 300px 200px auto;
    grid-gap: 10px;
    justify-content: flex-start;
    align-items: flex-end;
  }
  &_services {
    background-color: $color-light;
    margin-left: 45px;
    &_item {
      font-size: 10px;
      &_header {
        display: grid;
        grid-template-columns: 200px 120px 200px auto;
        align-items: center;
        background-color: $color-disabled;
        padding: 5px 5px 5px 15px;
        &_indicator {
          display: flex;
        }
      }
    }
  }
  &_deliverables {
    padding: 10px 10px 10px 20px;
    &_item {
      display: grid;
      grid-template-columns: 250px 200px 200px auto;
      grid-gap: 10px;
      align-items: center;
      font-size: 10px;
      margin-right: 20px;
      padding: 5px;
      &_indicator {
        display: flex;
      }
      &_doc {
        display: flex;
        align-items: center;
        &_icon {
          margin-right: 5px;
          color: $color-check;
        }
      }
    }
  }
  &_color {
    margin: 0 5px;
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-color: $color-check;
    &.alert {
      background-color: yellow;
    }
    &.danger {
      background-color: red;
    }
  }

  &_message {
    display: flex;
    font-size: 10px;
    text-align: center;
    align-items: center;
  }
}
