@import '../../../styles/colors';

.assignationModal {
  &_title {
    font-size: 14px;
    margin-bottom: 20px;
    &_service {
      color: $color-primary;
      font-family: 'OpenSans-Bold';
    }
  }
  &_select {
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 40px;
    &_list {
      border-radius: 5px;
      background-color: $color-light;
      padding: 8px;
      &_title {
        padding-bottom: 10px;
      }
      &_item {
        font-size: 12px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
