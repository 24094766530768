@import '../../../styles/colors';

.clientDocumentModal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  // align-items: center;
  // margin-bottom: 20px;
  // margin-top: 20px;

  &.edit {
    padding-top: 0;
    grid-template-columns: 1fr 1fr;
  }
}
.space-between {
  // margin-bottom: 10px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
