.documentsCatalogue {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &_filters {
      display: flex;
      align-items: flex-end;
      &_filterby {
        width: 300px;
        margin-left: 20px;
      }
    }
  }
}
