@import './../../styles/colors';

$transition-speed: 0.2s;

.splash {
  width: 100vw;
  height: 100vh;
  background-color: $color-primary;
  display: grid;
  justify-items: center;
  align-items: center;
  &_container {
    background-color: white;
    width: auto;
    height: auto;
    border-radius: 10px;
    text-align: right;
    display: grid;
    padding: 8% 15% 8% 15%;
    align-items: center;
    grid-template-columns: auto auto;
    margin: 10px;
    &_text {
      font-size: 1.8em;
      margin-left: 0.8em;
    }
    &_loader {
      border: 6px solid #f3f3f3;
      border-radius: 50%;
      border-top: 6px solid #3498db;
      width: 60px;
      height: 60px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media only screen and (max-width: 450px) {
    &_container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
      text-align: center;
      &_text {
        font-size: 1.8em;
        margin-right: 0;
      }
    }
  }
}
