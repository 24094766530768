@import 'colors';

.g-page-header {
  padding: 10px;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.g-button {
  padding: 0;
  &.delete {
    background: $color-delete;
  }

  &.cancel {
    background: $color-medium;
    &:hover {
      background-color: $color-medium;
    }
  }

  &.disabled {
    background: $color-disabled;
    &:hover {
      background-color: $color-disabled;
    }
  }

  &.complementary {
    background: $color-complementary;
    &:hover {
      background-color: $color-complementary;
    }
  }

  &.check {
    background: $color-check;
    &:hover {
      background-color: $color-check;
    }
  }
}

.g-icon {
  width: auto;
  height: 20px;
}

.g-actions-cell {
  display: flex;
  flex-direction: row;
  border: 1px solid $color-cancel;
  border-radius: 8px;
}

.g-row-action-button {
  background: $color-primary;
  color: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  margin: 5px;

  &.delete {
    background: $color-delete;
  }

  &.disabled {
    background: $color-disabled;
    cursor: auto;
  }

  &.secondary {
    background: $color-secondary;
  }

  &.complementary {
    background: $color-complementary;
  }

  &.check {
    background: $color-check;
  }

  &.normal {
    background: $color-normal;
  }
  &.message {
    background: $color-check;
    width: 40px !important;
    height: 40px !important;
  }
  &.new_message {
    background: $color-delete;
    width: 40px !important;
    height: 40px !important;
  }
}

.g-toast {
  border-radius: 10px !important;
  padding: 15px !important;
  border: 2px solid white;
}

.g-activeicon {
  color: $color-medium;
  width: 25px !important;
  height: 25px !important;
  &.active {
    color: $color-check;
  }
}
