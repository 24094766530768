.serviceDocs {
  height: 300px;
  &_header {
    display: flex;
    flex-justify: space-between;
    &_filters {
      display: flex;
      flex-justify: flex-start;
    }
  }
}
