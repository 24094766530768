.clients {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &_text_redirect {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  &_drive_link {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    &_ref {
      width: 500px;
      font-size: 10px;
      display: grid;
      grid-template-columns: 1fr;
      text-align: left;
    }
  }
}
