$color-primary: #b20839;
$color-secondary: #9d8d7e;
$color-complementary: #5eb569;
$color-complementary-2: #ced6de;
$color-complementary-3: #687d95;
$color-complementary-4: #475b7a;
$color-light: #f4f4f4;
$color-white: #ffffff;
$color-dark: #343434;
$color-medium: #bdbdbd;
$color-normal: #707070;
$color-disabled: #e4e4e4;
$color-cancel: #adadad;
$color-delete: #fa6347;
$color-check: #07b31c;

//$color-primary: #193552;
