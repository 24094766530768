@import './../../styles/colors';

.woServiceDocs {
  height: 300px;
  &_header {
    display: flex;
    justify-content: space-between;
    &_filters {
      display: flex;
      justify-content: flex-start;
    }
    &_buttons {
      display: grid;
      grid-template-columns: 250px 250px 250px;
      grid-gap: 10px;
    }
  }
  &_assignation {
    display: flex;
    align-items: center;
  }
  &_training {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.clickEffect {
  cursor: pointer;
  &_disabled {
    background: $color-disabled;
  }
}
