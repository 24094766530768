@import '../../../styles/colors';

.companymodal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  #MUI_name {
    grid-column: 1 / 3;
  }
  #MUI_legal_name {
    grid-column: 1 / 4;
  }
  #MUI_legal_representative {
    grid-column: 1 / 4;
  }
}
.contact_form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
