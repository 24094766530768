@import '../../../styles/colors';

.assignationInfoModal {
  &_header {
    background-color: $color-light;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;

    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;

    &_item {
      &_field {
        font-family: 'OpenSans-Bold';
        font-size: 11px;
      }
      &_data {
        font-size: 14px;
      }
    }
  }
  &_docs {
    display: flex;
    &_title {
      font-family: 'OpenSans-Bold';
      font-size: 14px;
      margin-bottom: 10px;
    }
    &_list {
      flex: 1;
      &_item {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 10px;
        &_name {
          margin-left: 5px;
        }
      }
    }
  }
}
